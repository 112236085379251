import { clientConsoleLogger } from '@utils/logger/internal/console';
import { newRelicReport } from '@utils/logger/internal/newrelic';

interface Params {
	log_message: string;
	newRelic?: boolean;
	optionalParams?: Record<string, string | number | boolean>;
	errorToLog?: Error;
}

type ErrorParams = Params & Required<Pick<Params, 'errorToLog'>>;

const logger = (
	level: 'log' | 'warn' | 'error',
	newRelicLevel: 'INFO' | 'WARN' | 'ERROR',
	{ log_message, errorToLog, optionalParams = {}, newRelic }: Params,
) => {
	if (SERVER) {
		const serverLogger = require('@server/utils/serverLogger');

		serverLogger.ServerLogger[level](
			log_message,
			errorToLog ? { ...optionalParams, error: errorToLog } : optionalParams,
		);
	} else {
		clientConsoleLogger(level, log_message, errorToLog, optionalParams);
	}

	if (newRelic) {
		newRelicReport(newRelicLevel, log_message, errorToLog);
	}
};

export const Logger = {
	log: (params: Omit<Params, 'errorToLog'>) => logger('log', 'INFO', params),
	warn: (params: Omit<Params, 'errorToLog'>) => logger('warn', 'WARN', params),
	error: (params: ErrorParams) => logger('error', 'ERROR', params),
};
