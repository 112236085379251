import parser from 'ua-parser-js';

export function getPlatform():
	| 'mobile web'
	| 'desktop web'
	| 'webview android' {
	if (
		parser()?.browser?.name === 'Chrome WebView' &&
		parser()?.os?.name === 'Android'
	)
		return 'webview android';

	const mobileDeviceTypes = ['mobile', 'tablet'];
	const isMobile = mobileDeviceTypes.includes(parser().device.type || '');
	return isMobile ? 'mobile web' : 'desktop web';
}
