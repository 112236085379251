type Entries<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T][];
type Keys<T> = (keyof T)[];
type Values<T> = T[keyof T][];

export const entries = <T extends object>(obj: T): Entries<T> =>
	Object.entries(obj) as Entries<T>;

export const keys = <T extends object>(obj: T): Keys<T> =>
	Object.keys(obj) as Keys<T>;

export const values = <T extends object>(obj: T): Values<T> =>
	Object.values(obj) as Values<T>;
