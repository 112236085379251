import type { AgentBase } from '@newrelic/browser-agent/loaders/agent-base';
import { clientConsoleLogger } from './console';

type NewRelicLogLevels = 'INFO' | 'WARN' | 'ERROR';

const waitTillAvailable = (
	resolve: (value: AgentBase) => void,
	reject: (reason: Error) => void,
	attempts = 1,
) => {
	const retries = 10;
	const ms = 300;
	setTimeout(() => {
		if (attempts >= retries) {
			reject(new Error("newrelic couldn't be found"));
			return;
		}

		if (window.newrelic) {
			resolve(window.newrelic);
			return;
		}

		waitTillAvailable(resolve, reject, attempts + 1);
	}, ms);
};

const waitForNewRelic = () => {
	if (SERVER) {
		return null;
	}

	return new Promise(
		(resolve: (value: AgentBase) => void, reject: (reason: Error) => void) => {
			const newRelicAgent = window.newrelic;
			if (newRelicAgent) {
				resolve(newRelicAgent);
				return;
			}

			waitTillAvailable(resolve, reject);
		},
	);
};

export const newRelicReport = (
	level: NewRelicLogLevels,
	log_message: string,
	errorToLog?: Error,
) => {
	if (!INCLUDE_NEWRELIC) return;

	if (SERVER) {
		// For server, you have to set attributes at a transaction level with setCustomAttributes or setCustomAttribute
		const newrelic = require('newrelic');
		newrelic.recordLogEvent({
			message: log_message,
			level,
			error: errorToLog,
		});

		if (errorToLog) {
			newrelic.noticeError(errorToLog, { log_message });
		}
		return;
	}

	waitForNewRelic()
		?.then((newrelic) => {
			// For client, we are going to set context at a browser level with setCustomAttribute
			newrelic.log(log_message, {
				level,
				customAttributes: errorToLog
					? {
							error: errorToLog,
						}
					: undefined,
			});

			if (errorToLog) {
				newrelic.noticeError(errorToLog, { log_message });
			}
		})
		.catch((error) => {
			clientConsoleLogger('error', error.message);
		});
};
