export const clientConsoleLogger = (
	level: 'log' | 'warn' | 'error',
	log_message: string,
	errorToLog?: Error,
	optionalParams: Record<string, unknown> = {},
) => {
	if (process.env.NODE_ENV === 'production') return;

	if (Object.keys(optionalParams).length > 0 || errorToLog) {
		console[level]({
			message: log_message,
			context: optionalParams,
			error: errorToLog,
		});
	} else {
		console[level](log_message);
	}
};
