const throttle = (func: Function, timeFrame: number) => {
	let lastTime = 0;
	/* eslint-disable @typescript-eslint/no-explicit-any */
	return (...args: Array<any>) => {
		const now = new Date().getTime();
		if (now - lastTime >= timeFrame) {
			func(...args);
			lastTime = now;
		}
	};
};

export default throttle;
