export const generateSeed = (signature = '') => {
	const date = new Date();
	const year = date.getUTCFullYear();
	// Adding 1 to the month since it's 0-based and was not 0-based on Classic
	const month =
		date.getUTCMonth() + 1 < 10
			? `0${date.getUTCMonth() + 1}`
			: date.getUTCMonth() + 1;
	const dayOfMonth = date.getUTCDate();
	const hour =
		date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();

	return `${year}${month}${dayOfMonth}${hour}${signature}`;
};
