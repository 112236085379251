import type { Experiments } from '@typings/redux';
import type {
	AnyAssignment,
	Configurations,
	Mappings,
} from 'types/experiments';

const getVariantId = (
	experimentConfig: Configurations,
	experimentId: string,
	bucketName: AnyAssignment,
) => {
	for (const property in experimentConfig) {
		if (property === experimentId) {
			const variants = experimentConfig[property as keyof Mappings]?.variants;

			const variant = variants?.find((v) => v.variant_name === bucketName);
			const variantId = variant?.variant_id;

			return variantId;
		}
	}
};

const generateExperimentHeader = (experiments: Experiments | undefined) => {
	if (!experiments) {
		return {};
	}

	const experimentVariantObj = Object.entries(experiments.assignments).map(
		([experimentId, bucketName]) => {
			const variantId = getVariantId(
				experiments.configurations,
				experimentId,
				bucketName,
			);
			return { [experimentId]: variantId };
		},
	);

	const headerValue = experimentVariantObj
		.map((obj) => `${Object.keys(obj)}=${Object.values(obj)}`)
		.join();

	const headers = {
		'TKWW-Flipper-IDs': headerValue,
	};

	return headers;
};

export { getVariantId, generateExperimentHeader };
