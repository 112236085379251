/* eslint-disable @typescript-eslint/no-unused-vars */

const noop = () => {
	// Do nothing
};

const noopBoolean = () => false;

const noopPromise = () => Promise.resolve();

const noopParameters = (..._args: unknown[]) => {
	// Do nothing
};

const noopParametersPromise = (..._args: unknown[]) => Promise.resolve();

export {
	noop,
	noopBoolean,
	noopPromise,
	noopParameters,
	noopParametersPromise,
};

export default noop;
