export const SORT_DIRECTIONS = {
	distance: { type: 'distance', order: 'asc' },
	featured: { type: 'featured', order: 'asc' },
	recommended: { type: 'recommended', order: 'asc' },
	'review-rating': { type: 'review-rating', order: 'desc' },
};

export default function sortParam(sort) {
	return SORT_DIRECTIONS[sort] || SORT_DIRECTIONS.recommended;
}
