import { CookieStorage } from 'cookie-storage';
import { VISITOR_ID, VISIT_ID } from '../../constants/cookies';

const cookieStorage = new CookieStorage();

export function getVisitorId() {
	return cookieStorage.getItem(VISITOR_ID);
}

export function getVisitId() {
	return cookieStorage.getItem(VISIT_ID);
}
